import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APP_CONFIG } from '../environment/Env';
import { HTTPService } from '../service/http-service';
import { SharedService } from '../service/shared-service';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: [ './signin.component.css' ]
})
export class SigninComponent {
  message: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _httpService: HTTPService,
    private _cookie: CookieService,
    private _sharedService: SharedService 
  ) {
    
  }

  ngOnInit() {
    if(this._cookie.get("name") != "" && this._cookie.get("token") != "" && this._cookie.get("role") != "") {
      this.checkValidCookie();
    }
  }

  checkValidCookie() {
    let inputParams: any[] = [];
    inputParams[0] = {"key":"value","value":this._cookie.get("token")};
    this._httpService.get(APP_CONFIG.adminAPIPath+"/login/validateToken",inputParams).subscribe(data=>{
      let role:string = this._cookie.get("role");

      if(data["message"] == "VALIDATION_SUCCESS") {
        if(role == "AD" || role == "AN" || role == "RV") {
          window.location.href = APP_CONFIG.adminConsolePath;
        }
        else if(role == "SU" || role == "DU" || role == "TU" || role == "SA") {
          window.location.href = APP_CONFIG.userConsolePath;
        }
      }
    });    
  }

  loginForm: FormGroup = this._formBuilder.group({
    email: [,{validators: [Validators.required, Validators.email], updateOn: "change"}],
    password: [, { validators: [Validators.required], updateOn: "change" }],
    flag: [, { updateOn: "change" }]
  });

  login() {
    this._sharedService.openSpinner();

    let inputParams: any[] = [];
    inputParams[0] = {"key":"value","value":this.loginForm.get('password').value};
    this._httpService.get(APP_CONFIG.adminAPIPath+"/login/encode",inputParams).subscribe(data=>{
      let password = data["value"];
      this.validateUser(this.loginForm.get('email').value, password);
    });
  }

  validateUser(userId,password) {
    let userCredentials = {};
    userCredentials["userId"] = userId;
    userCredentials["password"] = password;

    this._httpService.post(APP_CONFIG.adminAPIPath+"/login/authenticate",userCredentials).subscribe(data=>{
        if(data != null) {
          if(data["message"] == "AUTH_SUCCESS") {
            if(this.loginForm.get("flag").value) {
              let date = new Date();
              date.setDate(date.getDate()+7);
              this._cookie.set("name",data["firstName"],date);
              this._cookie.set("lastName",data["lastName"],date);
              this._cookie.set("token",data["token"],date);
              this._cookie.set("role",data["role"],date);
            }
            else {
              this._cookie.set("name",data["firstName"]);
              this._cookie.set("lastName",data["lastName"]);
              this._cookie.set("token",data["token"]);
              this._cookie.set("role",data["role"]);
            }
            if(data["role"] == "AD" || data["role"] == "AN" || data["role"] == "RV") {
              window.location.href = APP_CONFIG.adminConsolePath;
            }
            else if(data["role"] == "SU" || data["role"] == "DU" || data["role"] == "TU" || data["role"] == "SA") {
              window.location.href = APP_CONFIG.userConsolePath;
            }
            if(data["role"] == "IO") {
              window.location.href = APP_CONFIG.docConsolePath;
            }
          }
          else {
            this.message = data["message"];
          }                    
        }
        this._sharedService.closeSpinner();
    },error=>{
      this.message = "Failed to authenticate!"
    });
  }
}