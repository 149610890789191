<div class="header" (window:resize)="windowResize($event)">
    <div>
        <div style="width:25%;float:left;height:73px;">
            <div style="float: left; width: 100%; height:100%; min-width: 200px;">
                <a routerLink="/" routerLinkActive="active">
                    <img src="assets/images/logo.JPG" width="100%" height="99%"/>
                </a>
            </div>
        </div>
        <div *ngIf="setMobileMenuPanel" (click)="toggleMobileMenu()" style="width:70%;float:right;height:75px;font-size: 12px;text-align: right;">
            <button mat-button style="height:73px;width:50px;">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <div *ngIf="!setMobileMenuPanel" style="width:70%;float:right;height:75px;font-size: 16px;text-align: right; margin-top: 5px;">
            <div class="top-menu">
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a routerLink="/newsRoom" routerLinkActive="active">News Room</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a routerLink="/demoRequest" routerLinkActive="active">Request for a Demo</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a routerLink="/signin" routerLinkActive="active">Login</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a routerLink="/signup" routerLinkActive="active">Register</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
            </div>		
            <br/>
            <div>
                <nav role='navigation'>
                    <ul>
                        <li><a routerLink="/" routerLinkActive="active">Home</a></li>
                        <li><a routerLink="/aboutDQ" routerLinkActive="active">About Us</a></li>
                        <li><a routerLink="/subscribers" routerLinkActive="active">Subscribers</a></li>
                        <li><a routerLink="/insight" routerLinkActive="active">Insight</a></li>
                        <li><a routerLink="/subscription" routerLinkActive="active">Subscription</a></li>
                        <li><a routerLink="/contact" routerLinkActive="active">Contact Us</a></li>
                    </ul>
                </nav>  
            </div>
        </div>
    </div>		
</div>
<div class="mobile-menu-container" *ngIf="showMobileMenuPanel">
    <div class="mobile-menu" (click)="navigate('/')">Home</div>
    <div class="mobile-menu" (click)="navigate('/aboutDQ')">About Us</div>
    <div class="mobile-menu" (click)="navigate('/subscribers')">Subscribers</div>
    <div class="mobile-menu" (click)="navigate('/insight')">Insight</div>
    <div class="mobile-menu" (click)="navigate('/subscription')">Subscription</div>
    <div class="mobile-menu" (click)="navigate('/contact')">Contact Us</div>
    <div class="mobile-menu" (click)="navigate('/newsRoom')">News Room</div>
    <div class="mobile-menu" (click)="navigate('/demoRequest')">Request for a Demo</div>
    <div class="mobile-menu" (click)="navigate('/signup')">Register</div>
    <div class="mobile-menu" (click)="navigate('/signin')">Login</div>
</div>