<div id="subscription" style="min-height: calc(100vh - 175px);">
    <br/><br/>
    <div class="content-box">        
        <h3 style="text-align:center">Subscription</h3>        
        <div style="width: 100%;">
            <div class="columns">
                <ul class="price">
                    <li class="header">&nbsp;</li>
                    <li class="grey">
                        <span class="anchor" (click)="quoteAction()">Need a Quote?</span>
                    </li>
                    <li>
                        <div class="button">
                            <a routerLink="/demoRequest" routerLinkActive="active">Request for Demo</a>
                        </div>
                    </li>
                    <li class="grey">
                        <span class="anchor" (click)="forwardAction()">Forward Friend</span>
                    </li>
                </ul>
            </div>
            <div class="columns">
                <ul class="price">
                    <li class="header">Standard Plan</li>
                    <li>$ {{standardPrice}} / year</li>
                    <li class="grey">
                        <span class="anchor" (click)="action()">Multi User/Multi Year Plan</span>
                    </li>
                    <li>
                        <div class="button">
                            <a routerLink="/signup" routerLinkActive="active">Sign Up</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="columns">
                <ul class="price">
                    <li class="header">&nbsp;</li>
                    <li>Have any question ?</li>
                    <li><div class="button"><a (click)="callbackAction()" style="cursor: pointer;">Request a call back</a></div></li>
                    <li class="grey">Write us at <a href="mailto:info@drugquester.com">info@drugquester.com</a></li>
                </ul>
            </div>
        </div>
    </div>
    <br/><br/>

    <div *ngIf="showPriceCalculationPanel" class="popup">
        <div class="content-box" style="background-color: #FFFFFF; margin-top: 10%;" *ngIf="showPriceCalculationPanel">
            <form [formGroup]="calculationForm">
                <table align="center">
                    <tr>
                        <td>
                            <mat-form-field class="form-field">
                                <mat-label>No of Users</mat-label>
                                <input type="number" min=1 required matInput formControlName="users"> 
                                <mat-error *ngIf="calculationForm.get('users').hasError('required')">
                                    No of Users is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="calculationForm.get('users').hasError('min')">
                                    No of Users should be greater than <strong>0</strong>
                                </mat-error>               
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="form-field">
                                <mat-label>No of Years</mat-label>
                                <input matInput min=1 required formControlName="years" type="number">                
                                <mat-error *ngIf="calculationForm.get('years').hasError('required')">
                                    No of Years is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="calculationForm.get('years').hasError('min')">
                                    No of Years should be greater than <strong>0</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <button class="form-field" mat-raised-button color="primary" [disabled]="calculationForm.invalid" (click)="calculate()">Estimate</button>
                        </td>
                        <td>
                            <button class="form-field" mat-raised-button color="warn" (click)="showPriceCalculationPanel = !showPriceCalculationPanel">Close</button>
                        </td>
                    </tr>
                </table>
            </form>     

            <div class="columns" style="float: unset;" *ngIf="isPriceCalculated">
                <ul class="price">
                  <li class="header" style="background-color:rgba(0,212,255,1)">Best price for You!</li>
                  <li>Total Price: <span class="strike">$ {{totalPrice}}</span> $ {{priceAfterDiscount}}</li>
                  <li>Discount: {{discount}} %</li>              
                  <li>$ {{pricePerYearPerUser}} / year / user</li>
                  <li class="grey">
                      <div class="button">
                        <a routerLink="/signup" routerLinkActive="active" >Sign Up</a>
                      </div>
                  </li>
                </ul>
            </div>                
        </div>    
    </div>

    <subscription-popup 
        *ngIf="showPopUp && showQuoteRequestPanel" 
        [flag]="flag"
        [showQuoteRequestPanel]="showQuoteRequestPanel"
        (cancelEvent)="showPopUp = !showPopUp"
    ></subscription-popup>
    <subscription-popup 
        *ngIf="showPopUp && showCallbackPanel" 
        [flag]="flag"
        [showQuoteRequestPanel]="showCallbackPanel"
        (cancelEvent)="showPopUp = !showPopUp"
    ></subscription-popup>
    <subscription-popup
        *ngIf="showPopUp && showForwardRequestPanel"
        [flag]="flag"
        [showForwardRequestPanel]="showForwardRequestPanel"
        (cancelEvent)="showPopUp = !showPopUp"
    ></subscription-popup>

</div>

