<div class="header-main">
    <header></header>
</div>
<div class="content-main">
    <router-outlet></router-outlet>
    <footer></footer>
</div>

<div class="backdrop" *ngIf="showPrivacyPage"></div>
<div class="acceptCookie" id="cookiePopUp">
    <div style="float:right; ">
        <button mat-raised-button style="margin-right: 10px;margin-top:10px; width:10vw;" color="primary" (click)="acceptCookie()">Accept</button>
    </div>
    <div style="width:80%; height: 100%; overflow: scroll;"><privacy></privacy></div>    
</div>

<div class="spinner-div">
    <div style="margin-top: 20%;">
      <mat-spinner style="margin:0 auto;"></mat-spinner>
    </div>
  </div>