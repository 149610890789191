import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from '../environment/Env';
import { SharedService } from '../service/shared-service';
import { TncPopupComponent } from '../tnc/tnc-popup.component';


@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: [ './signup.component.css' ]
})
export class SignupComponent {
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  standardPrice: number;
  rateChart: any;
  countries: any;
  countrycode: string = "";
  presentCountry: string = ""
  fxRate: number = 1;

  constructor(
    private formBuilder: FormBuilder, 
    public _dialog: MatDialog, 
    private _http: HttpClient, 
    private _sharedService: SharedService,
    private _route: ActivatedRoute
  ) {
    
    this._http.get("assets/meta-data/standard-price.json").subscribe(data=>{
      this.standardPrice = data["standardPrice"];   
    });

    this._http.get("assets/meta-data/discount-rates.json").subscribe(data=>{
      this.rateChart = data;
    });

    this._http.get("assets/meta-data/country-list.json").subscribe(data=>{
      this.countries = data;
    });

  }

  referenceId: string;
  subscriptionType: string = "";

  registerForm: FormGroup = this.formBuilder.group({
    firstName: [, { validators: [Validators.required], updateOn: "change" }],
    lastName: [, { validators: [Validators.required], updateOn: "change" }],
    email: [,{validators: [Validators.required, Validators.email], updateOn: "change",}],
    password: [, { validators: [Validators.required, Validators.minLength(8)], updateOn: "change" }],
    confirmPassword: [, { validators: [Validators.required, Validators.minLength(8)], updateOn: "change" }],
    subscription: ['I', { validators: [Validators.required], updateOn: 'change' }],
    tnc: [, { validators: [Validators.required], updateOn: 'change' }],
    country: [, { validators: [Validators.required], updateOn: 'change' }],
    users: [, null],
    years: [, null],
    recaptcha: ['', Validators.required],
    company: [, { validators: [Validators.required], updateOn: "change" }],
    position: [, { validators: [Validators.required], updateOn: "change" }],
    department: [, null],
    city: [, Validators.required],
    contact: [, Validators.required],
    trial: [, null]
  });

  message: string = "";
  emailId: string;

  signup() {
    this.emailId = this.registerForm.get("email").value;
    this._sharedService.openSpinner();
    let userData = {};
    userData["firstName"] = this.registerForm.get("firstName").value;
    userData["lastName"] = this.registerForm.get("lastName").value;
    userData["email"] = this.registerForm.get("email").value;
    userData["password"] = this.registerForm.get("password").value;
    userData["company"] = this.registerForm.get("company").value;
    userData["position"] = this.registerForm.get("position").value;
    userData["department"] = this.registerForm.get("department").value;
    userData["city"] = this.registerForm.get("city").value;
    userData["contact"] = this.countrycode + this.registerForm.get("contact").value;
    userData["subscriptionType"] = "S"; //this.subscriptionType;
    userData["currency"] = this.currency;
    userData["countryCode"] = this.countrycode;
    if(this.registerForm.get("trial").value) {
      userData["trial"] = "Y";
    }
    else {
      userData["trial"] = "N";
    }
    if(this.subscriptionType == "M") {
      userData["users"] = this.registerForm.get("users").value;
      userData["years"] = this.registerForm.get("years").value;
      userData["amount"] = this.priceAfterDiscount;
    }
    else {
      userData["amount"] = this.totalPrice;
    }
    this.countries.forEach(element => {
      if(element["code"] == this.countrycode) {
        userData["country"] = element["name"];
      }
    });
    //console.log(userData);
    this._http.post(APP_CONFIG.adminAPIPath+"/public/signup",userData).subscribe(data=>{
      this.message = data["message"];
      this._sharedService.closeSpinner();
    },
    error=>{
      this.message = "Account activation failed";
      this._sharedService.closeSpinner();
    });
  }

  openTnCDialog() {
    const dialogRef = this._dialog.open(TncPopupComponent);  
    dialogRef.afterClosed().subscribe(result => {});
      
  }

  totalPrice: number;
  discount: number;
  priceAfterDiscount: number;
  pricePerYearPerUser: number;
  currency: string = "USD";

  siteKey: string = "6LdJ3qgaAAAAAFHCNHgr9jttubbYSxVBnApQzNeN";

  handleSuccess(event) {

  }

}

