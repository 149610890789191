<div class="content-background" *ngIf="message == ''">
    <br/><br/>
    <div id="signup" style="min-height: calc(100vh);" class="login-panel">
        <h3 style="text-align: center;">Welcome to Drug Quester. Please register here.</h3>
        <form [formGroup]="registerForm">
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Email ID & Password</b>
                    </mat-panel-title>                    
                </mat-expansion-panel-header>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Email</mat-label>
                    <input type="email" required matInput formControlName="email" placeholder="Ex. pat@example.com">
                    <mat-error *ngIf="registerForm.get('email').hasError('email') && !registerForm.get('email').hasError('required')">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field hintLabel="Minimum 8 Characters" class="form-field-full-width">
                    <mat-label>Password</mat-label>
                    <input matInput required #password formControlName="password" type="password">
                    <mat-hint align="end">{{password.value?.length || 0}}/8</mat-hint>
                    <mat-error *ngIf="registerForm.get('password').hasError('required')">
                        Password is required!
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('password').hasError('minlength')">
                        Password should be of minimum 8 chars
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field class="form-field-full-width">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput required #confirm type="password" formControlName="confirmPassword" pattern="{{ password.value }}">       
                    <mat-error *ngIf="registerForm.get('confirmPassword').hasError('required')">
                        Confirm your password.
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('confirmPassword').hasError('pattern')">
                        Password & Confirm password do not match.
                    </mat-error> 
                </mat-form-field>
    
                <mat-action-row>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>          
            </mat-expansion-panel>
          
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Personal Details</b>
                    </mat-panel-title>                    
                </mat-expansion-panel-header>

                <mat-form-field class="form-field-full-width">
                    <mat-label>First Name</mat-label>
                    <input matInput required formControlName="firstName">
                    <mat-error *ngIf="registerForm.get('firstName').hasError('required')">
                        First Name is required!
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field class="form-field-full-width">
                    <mat-label>Last name</mat-label>
                    <input matInput required formControlName="lastName">
                    <mat-error *ngIf="registerForm.get('lastName').hasError('required')">
                        Last Name is required!
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="form-field-full-width">
                    <mat-label>Organization</mat-label>
                    <input matInput required formControlName="company">
                    <mat-error *ngIf="registerForm.get('company').hasError('required')">Please provide your Organization name</mat-error>
                </mat-form-field>

                <mat-form-field class="form-field-full-width">
                    <mat-label>Position</mat-label>
                    <input matInput required formControlName="position">
                    <mat-error *ngIf="registerForm.get('position').hasError('required')">Please provide your Position</mat-error>
                </mat-form-field>

                <mat-form-field class="form-field-full-width">
                    <mat-label>Department</mat-label>
                    <input matInput formControlName="department">
                </mat-form-field>

                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
          
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Address & Contact</b>
                    </mat-panel-title>                    
                </mat-expansion-panel-header>
                <mat-form-field>
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" [(value)]="countrycode">
                        <mat-option *ngFor="let country of countries" [value]="country.code">
                            {{country.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="registerForm.get('country').hasError('required')">Please select the country</mat-error>                    
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city">
                    <mat-error *ngIf="registerForm.get('city').hasError('required')">Please provide the city</mat-error>
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Contact No</mat-label>
                    <span matPrefix>{{countrycode}} &nbsp;</span>
                    <input type="tel" matInput formControlName="contact" placeholder="555-555-1234">                    
                    <mat-error *ngIf="registerForm.get('contact').hasError('required')">Contact number is required</mat-error>
                </mat-form-field>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Terms & Condition</b>
                    </mat-panel-title>                    
                </mat-expansion-panel-header>
                <mat-checkbox formControlName="tnc" class="form-field-full-width" #tc>
                    I agree to the <button mat-button (click)="openTnCDialog()"><u>terms and conditions</u></button>
                </mat-checkbox>
                <mat-error *ngIf="!tc.checked">
                    Please accept Terms & Conditions
                </mat-error>
                <ngx-recaptcha2 #captchaElem
                    [siteKey]="siteKey"
                    (success)="handleSuccess($event)"
                    [useGlobalDomain]="false"
                    [size]="'normal'"
                    [hl]="'en'"
                    [theme]="'light'"
                    [type]="'image'"
                    formControlName="recaptcha">
                </ngx-recaptcha2>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
        <br /><br />
        <p align="center" *ngIf="registerForm.invalid && registerForm.get('tnc').value == true">
            <mat-error *ngIf="registerForm.get('email').hasError('email') && !registerForm.get('email').hasError('required')">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="registerForm.get('email').hasError('required')">
                Email is required
            </mat-error>
            <mat-error *ngIf="registerForm.get('password').hasError('required')">
                Password is required!
            </mat-error>
            <mat-error *ngIf="registerForm.get('password').hasError('minlength')">
                Password should be of minimum 8 chars
            </mat-error>
            <mat-error *ngIf="registerForm.get('confirmPassword').hasError('required')">
                Confirm your password.
            </mat-error>
            <mat-error *ngIf="registerForm.get('confirmPassword').hasError('pattern')">
                Password & Confirm password do not match.
            </mat-error> 
            <mat-error *ngIf="registerForm.get('firstName').hasError('required')">
                First Name is required!
            </mat-error>	
            <mat-error *ngIf="registerForm.get('lastName').hasError('required')">
                Last Name is required!
            </mat-error>
            <mat-error *ngIf="registerForm.get('company').hasError('required')">Please provide your Organization name</mat-error>
            <mat-error *ngIf="registerForm.get('position').hasError('required')">Please provide your Position</mat-error>
            <mat-error *ngIf="registerForm.get('country').hasError('required')">Please select the country</mat-error>
            <mat-error *ngIf="registerForm.get('city').hasError('required')">Please provide the city</mat-error>
            <mat-error *ngIf="registerForm.get('contact').hasError('required')">Contact number is required</mat-error>
        </p>
        <p align="center">
            <mat-checkbox class="form-field-full-width" formControlName="trial"><h4>I want to try it first!</h4></mat-checkbox>
            <button mat-raised-button class="form-field-full-width" color="primary" (click)="signup()" [disabled]="registerForm.invalid">
                Register
            </button>
        </p>
        </form>
    </div>
    <br/><br/>
</div>
<br/><br/>
<div class="content-box" *ngIf="message != '' && message != 'PAYMENT_PENDING'">
    <h3 align="center" *ngIf="message == 'TRIAL_ACCOUNT'">
        Trial Account Activation is subject to Admin Approval. We will notify once it is Approved.
    </h3>
    <h3 align="center" *ngIf="message == 'success'">
        Thank you. Payment is successful. Reference ID: {{referenceId}}
        <br/>
        <a routerLink='/signin' routerLinkActive='active'>Signin</a>
    </h3>
    <h3 align="center" *ngIf="message == 'failure'">
        Payment is not processed successfully. Please contact the Sales team for verification
    </h3>
</div>
<div class="content-box" *ngIf="message != '' && message == 'PAYMENT_PENDING'">
    <h3 align="center" *ngIf="message == 'PAYMENT_PENDING'">
        Your account is created successfully.<br/><br/>
        <span align="center"><a href="#/signin">Log In</a></span>
    </h3>
    <br/>
</div>