import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { PrivacyPopupComponent } from './privacy/privacy-popup.component';


@Component({
  selector: 'drug-quester-public',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public _dialog: MatDialog) {
    
  }

  showPrivacyPage: boolean = true;

  ngOnInit() {
    setTimeout(()=>{
      document.getElementById("cookiePopUp").style.height="40vh";
    },1000); 
  }

  acceptCookie() {
    this.showPrivacyPage = false;
    document.getElementById("cookiePopUp").style.height="0";
  }
}
