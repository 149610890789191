import { Injectable, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common'; 

@Injectable()
export class SharedService {
    
    constructor(@Inject(DOCUMENT)document) {

    }

    jobPosition: string;
    jobDepartment: string;

    openSpinner() {
        (document.querySelector(".spinner-div") as HTMLElement).style.visibility = "visible";
    }

    closeSpinner() {
        (document.querySelector(".spinner-div") as HTMLElement).style.visibility = "hidden";
    }

    formatDate(dateStr:string):string {
        let date = new Date(dateStr);
        return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
    }
}